import axios from 'axios';

export class CcxtApi {
  async getAllExchanges() {
    let response = await axios.get('/data/exchanges/ccxt_exchanges');
    return response.data || [];
  }

  async getAllAdminExchanges() {
    let response = await axios.get('/admin/ccxt_exchanges/ccxt_exchanges');
    return response.data || [];
  }

  async getMyExchanges() {
    let response = await axios.get('/data/exchanges');
    return response.data || [];
  }

  async getTopPairs(uid) {
    let response = await axios.get('/data/exchanges/top_pairs', {
      params: { coin_uid: uid }
    });
    return response.data;
  }
  async getExchangePairs(params) {
    let response = await axios.post('/data/exchanges/exchange_pairs', {
      ...params
    });
    return response;
  }
  async updateExchange(params) {
    let response = await axios.post('/exchanges', {
      ...params
    });
    return response;
  }
  async getCoins(exchange) {
    let response = await axios.get('/data/exchanges/ccxt_exchange_coins', {
      params: { exchanges: exchange }
    });
    return response.data;
  }

  async fetchOHLCV(params) {
    let response = await axios.get('/data/exchanges/ohlcv_data', { params: { ...params } });
    return response.data || [];
  }

  getExchangeSymbol(pairs, base) {
    const quotes = ['USD', 'USDT', 'USDC', 'BTC'];
    for (const pair of pairs) {
      const baseSymbol = pair.base_symbol;
      const quoteSymbol = pair.quote_symbol;
      if (quotes.includes(quoteSymbol) && base === baseSymbol) {
        return pair.pair;
      }
    }
    return null;
  }
}
